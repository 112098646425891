import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6e0ea9c1 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _76b1e308 = () => interopDefault(import('../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _f9f0af20 = () => interopDefault(import('../pages/aup.vue' /* webpackChunkName: "pages/aup" */))
const _081c90a8 = () => interopDefault(import('../pages/careers/index.vue' /* webpackChunkName: "pages/careers/index" */))
const _278811d4 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _ae051bd2 = () => interopDefault(import('../pages/disclaimer.vue' /* webpackChunkName: "pages/disclaimer" */))
const _a8f36a0c = () => interopDefault(import('../pages/docs/index.vue' /* webpackChunkName: "pages/docs/index" */))
const _e472f6f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _586d03e8 = () => interopDefault(import('../pages/game-design-tool.vue' /* webpackChunkName: "pages/game-design-tool" */))
const _0ac00216 = () => interopDefault(import('../pages/games.vue' /* webpackChunkName: "pages/games" */))
const _566ce11d = () => interopDefault(import('../pages/glossary/index.vue' /* webpackChunkName: "pages/glossary/index" */))
const _00768d7b = () => interopDefault(import('../pages/learn/index.vue' /* webpackChunkName: "pages/learn/index" */))
const _ed8da434 = () => interopDefault(import('../pages/liveops-whitepaper.vue' /* webpackChunkName: "pages/liveops-whitepaper" */))
const _cba5712c = () => interopDefault(import('../pages/partners/index.vue' /* webpackChunkName: "pages/partners/index" */))
const _7946e506 = () => interopDefault(import('../pages/pricing/index.vue' /* webpackChunkName: "pages/pricing/index" */))
const _55950766 = () => interopDefault(import('../pages/search-results.vue' /* webpackChunkName: "pages/search-results" */))
const _201b9ef3 = () => interopDefault(import('../pages/terms-and-conditions/index.vue' /* webpackChunkName: "pages/terms-and-conditions/index" */))
const _2e393aa1 = () => interopDefault(import('../pages/community/explore.vue' /* webpackChunkName: "pages/community/explore" */))
const _537b1f35 = () => interopDefault(import('../pages/community/see-all.vue' /* webpackChunkName: "pages/community/see-all" */))
const _753147ce = () => interopDefault(import('../pages/resources/academic-research-publications.vue' /* webpackChunkName: "pages/resources/academic-research-publications" */))
const _65bfebba = () => interopDefault(import('../pages/articles/author/_slugAuthor/index.vue' /* webpackChunkName: "pages/articles/author/_slugAuthor/index" */))
const _bc3d6540 = () => interopDefault(import('../pages/articles/category/_slugCategory/index.vue' /* webpackChunkName: "pages/articles/category/_slugCategory/index" */))
const _5f821c9d = () => interopDefault(import('../pages/articles/page/_pageNumber.vue' /* webpackChunkName: "pages/articles/page/_pageNumber" */))
const _143a4e71 = () => interopDefault(import('../pages/careers/job/_jobSlug.vue' /* webpackChunkName: "pages/careers/job/_jobSlug" */))
const _4f99a6c2 = () => interopDefault(import('../pages/articles/author/_slugAuthor/page/_pageNumber.vue' /* webpackChunkName: "pages/articles/author/_slugAuthor/page/_pageNumber" */))
const _8b67318e = () => interopDefault(import('../pages/articles/category/_slugCategory/page/_pageNumber.vue' /* webpackChunkName: "pages/articles/category/_slugCategory/page/_pageNumber" */))
const _24eb6bf4 = () => interopDefault(import('../pages/articles/_slugPost.vue' /* webpackChunkName: "pages/articles/_slugPost" */))
const _f997a668 = () => interopDefault(import('../pages/docs/_docSlug.vue' /* webpackChunkName: "pages/docs/_docSlug" */))
const _54b63fd5 = () => interopDefault(import('../pages/glossary/_slug.vue' /* webpackChunkName: "pages/glossary/_slug" */))
const _9903b092 = () => interopDefault(import('../pages/learn/_learnSlug.vue' /* webpackChunkName: "pages/learn/_learnSlug" */))
const _3571f051 = () => interopDefault(import('../pages/community/_communityUser/profile.vue' /* webpackChunkName: "pages/community/_communityUser/profile" */))
const _693642a8 = () => interopDefault(import('../pages/community/_communityUser/_userAction.vue' /* webpackChunkName: "pages/community/_communityUser/_userAction" */))
const _e7e03984 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _6e0ea9c1,
    pathToRegexpOptions: {"strict":true},
    name: "about___en___default"
  }, {
    path: "/articles",
    component: _76b1e308,
    pathToRegexpOptions: {"strict":true},
    name: "articles___en___default"
  }, {
    path: "/aup",
    component: _f9f0af20,
    pathToRegexpOptions: {"strict":true},
    name: "aup___en___default"
  }, {
    path: "/careers",
    component: _081c90a8,
    pathToRegexpOptions: {"strict":true},
    name: "careers___en___default"
  }, {
    path: "/contact",
    component: _278811d4,
    pathToRegexpOptions: {"strict":true},
    name: "contact___en___default"
  }, {
    path: "/disclaimer",
    component: _ae051bd2,
    pathToRegexpOptions: {"strict":true},
    name: "disclaimer___en___default"
  }, {
    path: "/docs",
    component: _a8f36a0c,
    pathToRegexpOptions: {"strict":true},
    name: "docs___en___default"
  }, {
    path: "/en",
    component: _e472f6f4,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/game-design-tool",
    component: _586d03e8,
    pathToRegexpOptions: {"strict":true},
    name: "game-design-tool___en___default"
  }, {
    path: "/games",
    component: _0ac00216,
    pathToRegexpOptions: {"strict":true},
    name: "games___en___default"
  }, {
    path: "/glossary",
    component: _566ce11d,
    pathToRegexpOptions: {"strict":true},
    name: "glossary___en___default"
  }, {
    path: "/learn",
    component: _00768d7b,
    pathToRegexpOptions: {"strict":true},
    name: "learn___en___default"
  }, {
    path: "/liveops-whitepaper",
    component: _ed8da434,
    pathToRegexpOptions: {"strict":true},
    name: "liveops-whitepaper___en___default"
  }, {
    path: "/partners",
    component: _cba5712c,
    pathToRegexpOptions: {"strict":true},
    name: "partners___en___default"
  }, {
    path: "/pricing",
    component: _7946e506,
    pathToRegexpOptions: {"strict":true},
    name: "pricing___en___default"
  }, {
    path: "/search-results",
    component: _55950766,
    pathToRegexpOptions: {"strict":true},
    name: "search-results___en___default"
  }, {
    path: "/terms-and-conditions",
    component: _201b9ef3,
    pathToRegexpOptions: {"strict":true},
    name: "terms-and-conditions___en___default"
  }, {
    path: "/community/explore",
    component: _2e393aa1,
    pathToRegexpOptions: {"strict":true},
    name: "community-explore___en___default"
  }, {
    path: "/community/see-all",
    component: _537b1f35,
    pathToRegexpOptions: {"strict":true},
    name: "community-see-all___en___default"
  }, {
    path: "/en/about",
    component: _6e0ea9c1,
    pathToRegexpOptions: {"strict":true},
    name: "about___en"
  }, {
    path: "/en/articles",
    component: _76b1e308,
    pathToRegexpOptions: {"strict":true},
    name: "articles___en"
  }, {
    path: "/en/aup",
    component: _f9f0af20,
    pathToRegexpOptions: {"strict":true},
    name: "aup___en"
  }, {
    path: "/en/careers",
    component: _081c90a8,
    pathToRegexpOptions: {"strict":true},
    name: "careers___en"
  }, {
    path: "/en/contact",
    component: _278811d4,
    pathToRegexpOptions: {"strict":true},
    name: "contact___en"
  }, {
    path: "/en/disclaimer",
    component: _ae051bd2,
    pathToRegexpOptions: {"strict":true},
    name: "disclaimer___en"
  }, {
    path: "/en/docs",
    component: _a8f36a0c,
    pathToRegexpOptions: {"strict":true},
    name: "docs___en"
  }, {
    path: "/en/game-design-tool",
    component: _586d03e8,
    pathToRegexpOptions: {"strict":true},
    name: "game-design-tool___en"
  }, {
    path: "/en/games",
    component: _0ac00216,
    pathToRegexpOptions: {"strict":true},
    name: "games___en"
  }, {
    path: "/en/glossary",
    component: _566ce11d,
    pathToRegexpOptions: {"strict":true},
    name: "glossary___en"
  }, {
    path: "/en/learn",
    component: _00768d7b,
    pathToRegexpOptions: {"strict":true},
    name: "learn___en"
  }, {
    path: "/en/liveops-whitepaper",
    component: _ed8da434,
    pathToRegexpOptions: {"strict":true},
    name: "liveops-whitepaper___en"
  }, {
    path: "/en/partners",
    component: _cba5712c,
    pathToRegexpOptions: {"strict":true},
    name: "partners___en"
  }, {
    path: "/en/pricing",
    component: _7946e506,
    pathToRegexpOptions: {"strict":true},
    name: "pricing___en"
  }, {
    path: "/en/search-results",
    component: _55950766,
    pathToRegexpOptions: {"strict":true},
    name: "search-results___en"
  }, {
    path: "/en/terms-and-conditions",
    component: _201b9ef3,
    pathToRegexpOptions: {"strict":true},
    name: "terms-and-conditions___en"
  }, {
    path: "/resources/academic-research-publications",
    component: _753147ce,
    pathToRegexpOptions: {"strict":true},
    name: "resources-academic-research-publications___en___default"
  }, {
    path: "/en/community/explore",
    component: _2e393aa1,
    pathToRegexpOptions: {"strict":true},
    name: "community-explore___en"
  }, {
    path: "/en/community/see-all",
    component: _537b1f35,
    pathToRegexpOptions: {"strict":true},
    name: "community-see-all___en"
  }, {
    path: "/en/resources/academic-research-publications",
    component: _753147ce,
    pathToRegexpOptions: {"strict":true},
    name: "resources-academic-research-publications___en"
  }, {
    path: "/",
    component: _e472f6f4,
    pathToRegexpOptions: {"strict":true},
    name: "index___en___default"
  }, {
    path: "/en/articles/author/:slugAuthor",
    component: _65bfebba,
    pathToRegexpOptions: {"strict":true},
    name: "articles-author-slugAuthor___en"
  }, {
    path: "/en/articles/category/:slugCategory",
    component: _bc3d6540,
    pathToRegexpOptions: {"strict":true},
    name: "articles-category-slugCategory___en"
  }, {
    path: "/en/articles/page/:pageNumber?",
    component: _5f821c9d,
    pathToRegexpOptions: {"strict":true},
    name: "articles-page-pageNumber___en"
  }, {
    path: "/en/careers/job/:jobSlug?",
    component: _143a4e71,
    pathToRegexpOptions: {"strict":true},
    name: "careers-job-jobSlug___en"
  }, {
    path: "/en/articles/author/:slugAuthor?/page/:pageNumber?",
    component: _4f99a6c2,
    pathToRegexpOptions: {"strict":true},
    name: "articles-author-slugAuthor-page-pageNumber___en"
  }, {
    path: "/en/articles/category/:slugCategory?/page/:pageNumber?",
    component: _8b67318e,
    pathToRegexpOptions: {"strict":true},
    name: "articles-category-slugCategory-page-pageNumber___en"
  }, {
    path: "/articles/author/:slugAuthor",
    component: _65bfebba,
    pathToRegexpOptions: {"strict":true},
    name: "articles-author-slugAuthor___en___default"
  }, {
    path: "/articles/category/:slugCategory",
    component: _bc3d6540,
    pathToRegexpOptions: {"strict":true},
    name: "articles-category-slugCategory___en___default"
  }, {
    path: "/articles/page/:pageNumber?",
    component: _5f821c9d,
    pathToRegexpOptions: {"strict":true},
    name: "articles-page-pageNumber___en___default"
  }, {
    path: "/careers/job/:jobSlug?",
    component: _143a4e71,
    pathToRegexpOptions: {"strict":true},
    name: "careers-job-jobSlug___en___default"
  }, {
    path: "/en/articles/:slugPost",
    component: _24eb6bf4,
    pathToRegexpOptions: {"strict":true},
    name: "articles-slugPost___en"
  }, {
    path: "/en/docs/:docSlug",
    component: _f997a668,
    pathToRegexpOptions: {"strict":true},
    name: "docs-docSlug___en"
  }, {
    path: "/en/glossary/:slug",
    component: _54b63fd5,
    pathToRegexpOptions: {"strict":true},
    name: "glossary-slug___en"
  }, {
    path: "/en/learn/:learnSlug",
    component: _9903b092,
    pathToRegexpOptions: {"strict":true},
    name: "learn-learnSlug___en"
  }, {
    path: "/en/community/:communityUser?/profile",
    component: _3571f051,
    pathToRegexpOptions: {"strict":true},
    name: "community-communityUser-profile___en"
  }, {
    path: "/articles/author/:slugAuthor?/page/:pageNumber?",
    component: _4f99a6c2,
    pathToRegexpOptions: {"strict":true},
    name: "articles-author-slugAuthor-page-pageNumber___en___default"
  }, {
    path: "/articles/category/:slugCategory?/page/:pageNumber?",
    component: _8b67318e,
    pathToRegexpOptions: {"strict":true},
    name: "articles-category-slugCategory-page-pageNumber___en___default"
  }, {
    path: "/en/community/:communityUser?/:userAction?",
    component: _693642a8,
    pathToRegexpOptions: {"strict":true},
    name: "community-communityUser-userAction___en"
  }, {
    path: "/articles/:slugPost",
    component: _24eb6bf4,
    pathToRegexpOptions: {"strict":true},
    name: "articles-slugPost___en___default"
  }, {
    path: "/docs/:docSlug",
    component: _f997a668,
    pathToRegexpOptions: {"strict":true},
    name: "docs-docSlug___en___default"
  }, {
    path: "/en/:slug",
    component: _e7e03984,
    pathToRegexpOptions: {"strict":true},
    name: "slug___en"
  }, {
    path: "/glossary/:slug",
    component: _54b63fd5,
    pathToRegexpOptions: {"strict":true},
    name: "glossary-slug___en___default"
  }, {
    path: "/learn/:learnSlug",
    component: _9903b092,
    pathToRegexpOptions: {"strict":true},
    name: "learn-learnSlug___en___default"
  }, {
    path: "/community/:communityUser?/profile",
    component: _3571f051,
    pathToRegexpOptions: {"strict":true},
    name: "community-communityUser-profile___en___default"
  }, {
    path: "/community/:communityUser?/:userAction?",
    component: _693642a8,
    pathToRegexpOptions: {"strict":true},
    name: "community-communityUser-userAction___en___default"
  }, {
    path: "/:slug",
    component: _e7e03984,
    pathToRegexpOptions: {"strict":true},
    name: "slug___en___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
