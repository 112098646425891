import { render, staticRenderFns } from "./HomepageHeader.vue?vue&type=template&id=66e19586"
import script from "./HomepageHeader.vue?vue&type=script&lang=ts"
export * from "./HomepageHeader.vue?vue&type=script&lang=ts"
import style0 from "./HomepageHeader.vue?vue&type=style&index=0&id=66e19586&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderNavBar: require('/Users/teodorcazangiu/Documents/Workspace/website/wp-nuxt/components/header/HeaderNavBar.vue').default,DialogsWrapper: require('/Users/teodorcazangiu/Documents/Workspace/website/wp-nuxt/components/dialogs/DialogsWrapper.vue').default})
