import Vue from 'vue'
import DOMPurify from 'dompurify'

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    this.event = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', this.event)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', this.event)
  },
})

async function sanitizeAsync(el, binding) {
  const { default: DOMPurify } = await import('dompurify');
  el.innerHTML = DOMPurify.sanitize(binding.value, {
    ALLOWED_TAGS: [], // Disallow all tags
    ALLOWED_ATTR: []  // Disallow all attributes
  });
}

Vue.directive('sanitized-html', {
  async bind(el, binding) {
    await sanitizeAsync(el, binding);
  },
  async update(el, binding) {
    await sanitizeAsync(el, binding);
  }
});
